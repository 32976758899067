import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Layout } from "../dashboard/dashboard";
import { Box, Stack, Typography } from "@mui/material";
import { Btn } from "../payment";
import { Back } from "../../assets/Svg";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useDispatch } from "react-redux";
import { handleSelectedCom } from "../../sidebar/toggleSlice";
import { getClient, getCustomerLedgers } from "../../apiservices";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#52b335",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
}));

export default function SimpleTable() {
  const componentRef = React.useRef();

  const [state, setState] = React.useState({
    clientData: [],
  });
  const getClientsData = async () => {
    let res = await getCustomerLedgers();
    if (res.success) {
      setState((prev) => ({ ...prev, clientData: res.data }));
    }
  };
  React.useEffect(() => {
    getClientsData();
  }, []);
  const dispatch = useDispatch();
  const handleSelection = (arg) => {
    dispatch(handleSelectedCom(arg));
  };
  const printToPDF = () => {
    // Select the content you want to print to PDF by its ID or class
    const contentToPrint = document.getElementById("content-to-print");

    // Use html2canvas to capture the content as an image
    html2canvas(contentToPrint).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Set dimensions for the PDF
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 size
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add the captured image to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      // Download the PDF
      pdf.save("content.pdf");
    });
  };

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      ref={ref}
      id="content-to-print">
      <TableContainer component={Paper} sx={{ mt: 5, width: "90%" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>No#</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Contact</StyledTableCell>
              <StyledTableCell align="left">City</StyledTableCell>
              <StyledTableCell align="left">Current Balance</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.clientData?.map((row, key) => (
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {key + 1}
                </StyledTableCell>
                <StyledTableCell align="left">{row.client_name}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.contact}
                </StyledTableCell>
                <StyledTableCell align="left">{row.city_name}</StyledTableCell>
                <StyledTableCell align="left">
                  {parseFloat(row.opening_balance) + parseFloat(row.debit) - parseFloat(row.credit)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  ));
  return (
    <>
      <Layout
        justifyContent="space-between"
        alignItems={"center"}
        direction={"row"}>
        <Typography></Typography>
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "24px",
            fontWeight: 700,
            padding: 1,
          }}>
          Client Reports
        </Typography>
        <Stack direction={"row"}>
          {/* <ReactToPrint
            trigger={() => (
              <Btn>
                <PictureAsPdfIcon fontSize="small" sx={{ mx: 1 }} /> PDF
              </Btn>
            )}
            content={() => componentRef.current}
          /> */}

          <ReactToPrint
            trigger={() => (
              <Btn>
                <PrintIcon fontSize="small" sx={{ mx: 1 }} /> Print
              </Btn>
            )}
            content={() => componentRef.current}
          />
          <Btn onClick={() => handleSelection("dashboard")}>
            <Back sx={{ mx: 1 }} /> Back
          </Btn>
        </Stack>
      </Layout>
      <ComponentToPrint ref={componentRef} />
    </>
  );
}
