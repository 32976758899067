import axios from "axios";

var server = "https://shop.erorex.online/errorexb"
// var server = "http://localhost:8080/errorexb"

export const getData = async (data) => {
  try {
    let res = await axios.post(`${server}/get_data.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const loginAdmin = async (dataX) => {
  try {
    const data = {
      table: "users",
      filters: [
        {
          column_name: "username",
          operation: "=",
          value: dataX.username
        },
        {
          column_name: "password",
          operation: "=",
          value: dataX.password
        }
      ],
    }
    let res = await axios.post(`${server}/get_data.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const saveBank = async (dataX) => {
  let res = await axios.post(
    server + "/insert_data.php",
    {
      table: "banks",
      data: dataX,
    }
  );
  let json = res.data
  return json;
};

export const getBanks = async (dataX) => {
  try {
    const data = {
      table: "banks",
      // filters: [
      //   {
      //     column_name: "company_code",
      //     operation: "=",
      //     value: dataX.company_code
      //   }
      // ],
    }
    let res = await axios.post(`${server}/get_data.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const getBankLedgers = async () => {
  try {
    let res = await axios.get(`${server}/ledgers/get_bank_ledgers.php`);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const updateBank = async (dataX, id) => {
  const data = {
    table: "banks",
    data: dataX,
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/update_data.php`, data);
  let json = res.data;
  return json;
};

export const deleteBank = async (id) => {
  const data = {
    table: "banks",
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/delete_data.php`, data);
  let json = res.data;
  return json;
};

// export const getProductsSearch = async (userData) => {
//   let res = await axios.post(
//     `https://shrouded-tor-53503.herokuapp.com/products`,
//     {
//       request: {
//         method: "getModelsSearch",
//         data: userData,
//       },
//     }
//   );
//   let json = res.data.response.data;
//   return json;
// };
// export const saveBills  = async (dataX) => {
//   let res = await axios.post(
//     server + "/insert_data.php",
//     {
//       table: "sales",
//       data: dataX,
//     }
//   );
//   let json = res.data
//   return json;
// };

export const saveSale  = async (dataX) => {
  let res = await axios.post(
    server + "/insert_data.php",
    {
      table: "sales",
      data: dataX,
    }
  );
  let json = res.data
  return json;
};

export const getBills = async (dataX) => {
  try {
    const data = {
      table: "sales",
      // filters: [
      //   {
      //     column_name: "company_code",
      //     operation: "=",
      //     value: dataX.company_code
      //   }
      // ],
    }
    let res = await axios.post(`${server}/sale/get_sale.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const getBillsOfClient = async (client_id) => {
  try {
    const data = {
      client_id: client_id
    }
    let res = await axios.post(`${server}/sale/get_sale_of_clients.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const getSaleBetweenDates = async (startDate, endDate) => {
  try {
    const data = {
      start_date: startDate,
      end_date: endDate,
    }
    let res = await axios.post(`${server}/sale/get_client_sales_between_dates.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const getSaleProfiLossBetweenDates = async (startDate, endDate) => {
  try {
    const data = {
      start_date: startDate,
      end_date: endDate,
    }
    let res = await axios.post(`${server}/sale/get_sale_profit_loss_between_dates.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const getInvoice = async (voucher_no) => {
  try {
    const data = {
      table: "sales",
      filters: [
        {
          column_name: "voucher_no",
          operation: "=",
          value: voucher_no
        }
      ],
    }
    let res = await axios.post(`${server}/get_data.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const viewInvoice = async (voucher_no) => {
  if(voucher_no){
    try {
      const data = {
        voucher_no: voucher_no
      }
      let res = await axios.post(`${server}/sale/get_sale_invoice.php`, data);
      let json = [];
      if (res.data) {
        json = res.data;
      }
      return json;
    }
    catch (ex) {
      console.log(ex)
      return []
    }
  }
};

export const savePurchase  = async (dataX) => {
  let res = await axios.post(
    server + "/insert_data.php",
    {
      table: "purchases",
      data: dataX,
    }
  );
  let json = res.data
  return json;
};

export const updatePurchase = async (dataX, id) => {
  const data = {
    data: dataX
  }
  let res = await axios.post(`${server}/purchase/update_purchase.php`, data);
  let json = res.data;
  return json;
};

export const deletePurchase = async (voucher_no) => {
  const data = {
    table: "purchases",
    filters: [
      {
        column_name: "voucher_no",
        operation: "=",
        value: voucher_no
      }
    ]
  }
  let res = await axios.post(`${server}/delete_data.php`, data);
  let json = res.data;
  return json;
};

export const deleteBillZkTraders = async (voucher_no) => {
  const data = {
    table: "sales",
    filters: [
      {
        column_name: "voucher_no",
        operation: "=",
        value: voucher_no
      }
    ]
  }
  let res = await axios.post(`${server}/delete_data.php`, data);
  let json = res.data;
  return json;
};

export const getPurchaseInvoice = async (voucher_no) => {
  try {
    const data = {
      table: "purchases",
      filters: [
        {
          column_name: "voucher_no",
          operation: "=",
          value: voucher_no
        }
      ],
    }
    let res = await axios.post(`${server}/get_data.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const getPurchasesOfSuppliers = async (supplier_id) => {
  try {
    const data = {
      supplier_id: supplier_id
    }
    let res = await axios.post(`${server}/purchase/get_purchases_of_supplier.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const getPurchases = async (dataX) => {
  try {
    const data = {
      table: "purchases",
      // filters: [
      //   {
      //     column_name: "company_code",
      //     operation: "=",
      //     value: dataX.company_code
      //   }
      // ],
    }
    let res = await axios.post(`${server}/purchase/get_purchase.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const viewPurchaseInvoice = async (voucher_no) => {
  if(voucher_no){
    try {
      const data = {
        voucher_no: voucher_no
      }
      let res = await axios.post(`${server}/purchase/get_purchase_invoice.php`, data);
      let json = [];
      if (res.data) {
        json = res.data;
      }
      return json;
    }
    catch (ex) {
      console.log(ex)
      return []
    }
  }
};

export const getOutBills = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/bills`, {
    request: {
      method: "getOutstandingBills",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveSupplier = async (dataX) => {
  let res = await axios.post(
    server + "/insert_data.php",
    {
      table: "suppliers",
      data: dataX,
    }
  );
  let json = res.data
  return json;
};

export const getSupplier = async (dataX) => {
  try {
    const data = {
      table: "suppliers",
      // filters: [
      //   {
      //     column_name: "company_code",
      //     operation: "=",
      //     value: dataX.company_code
      //   }
      // ],
    }
    let res = await axios.post(`${server}/get_data.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const updateSupplier = async (dataX, id) => {
  const data = {
    table: "suppliers",
    data: dataX,
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/update_data.php`, data);
  let json = res.data;
  return json;
};

export const deleteSupplier = async (id) => {
  const data = {
    table: "suppliers",
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/delete_data.php`, data);
  let json = res.data;
  return json;
};

export const saveProduct = async (dataX) => {
  let res = await axios.post(
    server + "/insert_data.php",
    {
      table: "products",
      data: dataX,
    }
  );
  let json = res.data
  return json;
};

export const getProduct = async (dataX) => {
  try {
    const data = {
      table: "products",
      // filters: [
      //   {
      //     column_name: "company_code",
      //     operation: "=",
      //     value: dataX.company_code
      //   }
      // ],
    }
    let res = await axios.post(`${server}/get_data.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const getStock = async () => {
  try {
    let res = await axios.get(`${server}/stock/get_stock.php`);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const updateProduct = async (dataX, id) => {
  const data = {
    table: "products",
    data: dataX,
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/update_data.php`, data);
  let json = res.data;
  return json;
};

export const deleteProduct = async (id) => {
  const data = {
    table: "products",
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/delete_data.php`, data);
  let json = res.data;
  return json;
};

export const saveStockNew = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/stock`, {
    request: {
      method: "saveStockNew",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getStockNew = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/stock`, {
    request: {
      method: "getStockNew",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const updateStockNew = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/stock`, {
    request: {
      method: "updateStockNew",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const deleteStockNew = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/stock`, {
    request: {
      method: "deleteStockNew",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getClient= async (dataX) => {
  try {
    const data = {
      table: "clients",
      // filters: [
      //   {
      //     column_name: "company_code",
      //     operation: "=",
      //     value: dataX.company_code
      //   }
      // ],
    }
    let res = await axios.post(`${server}/get_data.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const saveClient = async (dataX) => {
  let res = await axios.post(
    server + "/insert_data.php",
    {
      table: "clients",
      data: dataX,
    }
  );
  let json = res.data
  return json;
};

export const updateClient = async (dataX, id) => {
  const data = {
    table: "clients",
    data: dataX,
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/update_data.php`, data);
  let json = res.data;
  return json;
};

export const deleteClient = async (id) => {
  const data = {
    table: "clients",
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/delete_data.php`, data);
  let json = res.data;
  return json;
};

export const savePaymentNew = async (dataX) => {
  console.log(dataX)
  let res = await axios.post(
    server + "/insert_data.php",
    {
      table: "payments",
      data: dataX,
    }
  );
  let json = res.data
  return json;
};

export const updateSpecificPayment = async (dataX, id) => {
  const data = {
    table: "payments",
    data: dataX,
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/update_data.php`, data);
  let json = res.data;
  return json;
};

export const getPayment = async (dataX) => {
  try {
    const data = {
      table: "payments",
      // filters: [
      //   {
      //     column_name: "company_code",
      //     operation: "=",
      //     value: dataX.company_code
      //   }
      // ],
    }
    let res = await axios.post(`${server}/get_data.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const getPaymentsBetweenDates = async (startDate, endDate) => {
  try {
    const data = {
      start_date: startDate,
      end_date: endDate,
    }
    let res = await axios.post(`${server}/payments/get_client_payments_between_dates.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const deletePayments = async (id) => {
  const data = {
    table: "payments",
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/delete_data.php`, data);
  let json = res.data;
  return json;
};


export const saveSupplierPayment = async (dataX) => {
  console.log(dataX)
  let res = await axios.post(
    server + "/insert_data.php",
    {
      table: "supplier_payments",
      data: dataX,
    }
  );
  let json = res.data
  return json;
};

export const updateSupplierPayment = async (dataX, id) => {
  const data = {
    table: "supplier_payments",
    data: dataX,
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/update_data.php`, data);
  let json = res.data;
  return json;
};

export const getSupplierPayment = async (dataX) => {
  try {
    const data = {
      table: "supplier_payments",
      // filters: [
      //   {
      //     column_name: "company_code",
      //     operation: "=",
      //     value: dataX.company_code
      //   }
      // ],
    }
    let res = await axios.post(`${server}/get_data.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const deleteSupplierPayments = async (id) => {
  const data = {
    table: "supplier_payments",
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/delete_data.php`, data);
  let json = res.data;
  return json;
};

export const saveCity = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "saveCity",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const getCity = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "getCity",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};

export const saveCountry = async (dataX) => {
  let res = await axios.post(
    server + "/insert_data.php",
    {
      table: "countries",
      data: dataX,
    }
  );
  let json = res.data
  return json;
};

export const getCountry = async (dataX) => {
  try {
    const data = {
      table: "countries",
    }
    let res = await axios.post(`${server}/get_data.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const updateCountry = async (dataX, id) => {
  const data = {
    table: "countries",
    data: dataX,
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/update_data.php`, data);
  let json = res.data;
  return json;
};

export const deleteCountry = async (id) => {
  const data = {
    table: "countries",
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/delete_data.php`, data);
  let json = res.data;
  return json;
};

export const getDailyPayment = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/payments`,
    {
      request: {
        method: "getDailyPayment",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};

export const getDailyProfitLoss = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/sales`, {
    request: {
      method: "getSalesProfitLossZRT",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const saveCategories = async (dataX) => {
  const data = {
    table: "categories",
    data: dataX,
  }
  let res = await axios.post(`${server}/insert_data.php`, data);
  let json = res.data;
  return json;
};

export const getCategories = async (dataX) => {
  try {
    const data = {
      table: "categories",
      // filters: [
      //   {
      //     column_name: "company_code",
      //     operation: "=",
      //     value: dataX.company_code
      //   }
      // ],
    }
    let res = await axios.post(`${server}/get_data.php`, data);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const updateCategory = async (dataX, id) => {
  const data = {
    table: "categories",
    data: dataX,
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/update_data.php`, data);
  let json = res.data;
  return json;
};

export const deleteCategory = async (id) => {
  const data = {
    table: "categories",
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/delete_data.php`, data);
  let json = res.data;
  return json;
};

export const updateBillZkTraders = async (dataX, id) => {
  const data = {
    data: dataX
  }
  let res = await axios.post(`${server}/sale/update_sale.php`, data);
  let json = res.data;
  return json;
};


export const getCustomerLedgers = async () => {
  try {
    let res = await axios.get(`${server}/ledgers/get_customer_ledgers.php`);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const getSupplierLedgers = async () => {
  try {
    let res = await axios.get(`${server}/ledgers/get_supplier_ledgers.php`);
    let json = [];
    if (res.data) {
      json = res.data;
    }
    return json;
  }
  catch (ex) {
    console.log(ex)
    return []
  }
};

export const saveUser = async (dataX) => {
  let res = await axios.post(
    server + "/insert_data.php",
    {
      table: "users",
      data: dataX,
    }
  );
  let json = res.data
  return json;
};

export const updateUser = async (dataX, id) => {
  const data = {
    table: "users",
    data: dataX,
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/update_data.php`, data);
  let json = res.data;
  return json;
};

export const deleteUser = async (id) => {
  const data = {
    table: "users",
    filters: [
      {
        column_name: "id",
        operation: "=",
        value: id
      }
    ]
  }
  let res = await axios.post(`${server}/delete_data.php`, data);
  let json = res.data;
  return json;
};