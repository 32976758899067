import {
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./countryTable";
import CustomDialogue from "../reuseableComp/Modal";
import { Typo } from "../payment";
import { CustomField } from "../client";
import { PersonLogo } from "../../assets/Svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import { getCountry, saveCountry, updateCountry } from "../../apiservices";
import { toast } from "react-toastify";
import Search from "../searchBar";
const Btn = styled(Button)({
  minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#52b335",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const schema = yup
  .object({
    fName: yup.string().required(),
  })
  .required();

const CountryComp = () => {
  const [state, setState] = useState({
    openModal: false,
    countriesData: [],
    check: false,
    updatedData: [],
    brandName: "",
    arrayList: [],
    cityName: "",
    country: "Pakistan",
    province: "",
    searchData: [],
  });

  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleModal = (data = "") => {
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      check: false,
      updatedData: data,
    }));
    if (data) {
      setState((prev) => ({
        ...prev,
        province: data.province_name,
        cityName: data.city_name,
      }));
    }
  };
  const handleChange = (e) => {
    let { value } = e.target;
    let { name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const getCount = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      // company_code: userData.company_code,
    };
    let res = await getCountry(obj);
    if (res.success) {
      setState((prev) => ({
        ...prev,
        countriesData: res.data
      }));
    }
    else{
      setState((prev) => ({
        ...prev,
        countriesData: []
      }));
    }
  };
  useEffect(() => {
    getCount();
  }, []);

  const onSubmit = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (state.check) {
      let obj = {
        company_code: userData.company_code,
        province_name: state.province,
        country_name: "Pakistan",
        city_name: state.cityName,
        updated_by: userData.id
      };
      let res = await updateCountry(obj, state.updatedData.id);
      if (res) {
        toast.success("City updated successfully");
        getCount();
        handleModal();
      }
    } else {
      let obj = {
        company_code: userData.company_code,
        province_name: state.province,
        country_name: "Pakistan",
        city_name: state.cityName,
        added_by: userData.id
      };
      let res = await saveCountry(obj);
      if (res) {
        toast.success("City saved successfully");
        getCount();
        handleModal();
      }
    }
  };
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };
  const updateData = (data) => {
    setState((prev) => ({ ...prev, countriesData: data }));
  };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <>
        <Layout
          justifyContent="space-between"
          alignItems={"center"}
          direction={"row"}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "24px",
              fontWeight: 700,
            }}></Typography>
          <Typography sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
            Countries
          </Typography>
          <Btn onClick={handleModal}>
            <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new City
          </Btn>
        </Layout>
        <Stack sx={{ padding: "20px" }}>
          <Stack
            justifyContent={"start"}
            alignItems={"start"}
            sx={{
              width: "50%",
            }}>
            {/* <Search
              data={state.searchData}
              updateData={(data) => updateData(data)}
              place1={" Search by City"}
              place2={""}
            /> */}
          </Stack>
          <DataTable
            data={state?.countriesData}
            getData={getCount}
            editCountry={(data) => handleModal(data)}
            handleCheck={handleCheck}
          />
        </Stack>
      </>
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ color: "#52b335" }}>
              <PersonLogo />
            </Box>
            <Typo>Add new City</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <Stack gap={2} sx={{ mb: 2 }}>
              <CustomField
                fullWidth
                id="outlined-select-currency"
                label="country"
                disabled
                value={state.country}
                onChange={handleChange}
                sx={{
                  background: "#F6F6F6",
                  "& label.Mui-focused": {
                    color: "#52b335",
                  },
                }}
              />
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  value={state.province}
                  name="province"
                  onChange={handleChange}
                  label="Select Province">
                  {["Punjab", "Kpk", "Sindh", "Balochistan", "GB", "Azad Kashmir"].map(
                    (option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    )
                  )}
                </CustomField>
                {errors.brandName && (
                  <p style={{ color: "red" }}>{errors.brandName?.message}</p>
                )}
              </Stack>
              <Stack direction={"row"} gap={2} sx={{ mb: 2 }}>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  value={state.cityName}
                  name="cityName"
                  onChange={handleChange}
                  label="City Name"></CustomField>
              </Stack>
            </Stack>

            <Stack spacing={3} direction={"row"}>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "200px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                size="small"
                sx={{
                  width: "200px",
                  textTransform: "none",
                  background: "#52b335",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "update city" : " save city"}
              </Button>
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default CountryComp;
