import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  salesData: {},
};

export const saleSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {
    handleSalesData: (state, payload) => {
      state.salesData = payload.payload;
    },
  },
});

export const { handleSalesData } = saleSlice.actions;

export default saleSlice.reducer;
