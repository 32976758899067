import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import { deleteBank } from "../../apiservices";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { CustomToolbar } from "../categories/categoryTable";

const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#52b335",
  "&:hover": {
    color: "#FFF",
    background: "#52b335",
  },
});

export default function DataTable({
  editBankDetails,
  bankList,
  handleCheck,
  getData,
  viewBank,
}) {
  const columns = [
    { field: "bank_name", headerName: "Bank Name", width: 200 },
    { field: "account_title", headerName: "Account Title", width: 300 },
    { field: "account_number", headerName: "Account No#", width: 200 },
    { field: "balance", headerName: "Balance", width: 200 },
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <strong>
         <Btn
            sx={{ height: '30px', width: '100px', mr: '5px', padding: 0 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => viewBank(params.row)}>
            View Ledger
          </Btn>
          <Btn
            title="Edit"
            variant="contained"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#eed202", color: "white" }}
            onClick={() => edit(params.row)}>
            <EditIcon fontSize="small" />
          </Btn>
          <Btn
            title="Delete"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "red", color: "white" }}
            variant="contained"
            onClick={() => deleteRow(params.row)}>
            <DeleteIcon fontSize="small" />
          </Btn>
          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
  ];
  const rows = bankList;
  const edit = (params) => {
    editBankDetails(params);
    handleCheck();
  };
  const deleteRow = async (params) => {
    /* eslint-disable no-restricted-globals */
    if(confirm(`Are you sure to delete "${params.bank_name}"`)){
      let res = await deleteBank(params.id);
      if (res) {
        toast.error("Bank deleted successfully");
        getData();
      }
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
