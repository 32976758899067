import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  purchasesData: {},
};

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    handlePurchasesData: (state, payload) => {
      state.purchasesData = payload.payload;
    },
  },
});

export const { handlePurchasesData } = purchaseSlice.actions;

export default purchaseSlice.reducer;
