import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { AppReg } from "../../assets/Svg";
import {
  deleteBillByClient,
  deleteBillZkTraders,
  getClientById,
} from "../../apiservices";
import { toast } from "react-toastify";
import moment from "moment";
import { CustomToolbar } from "../categories/categoryTable";
const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#52b335",
  "&:hover": {
    color: "#FFF",
    background: "#52b335",
  },
});

export default function DataTable({
  addSales,
  view,
  rowData,
  getBillData,
  clientData,
}) {
  // if (rowData) {
  //   rowData.forEach((element) => {
  //     if (!element?.id) {
  //       let filtered = clientData.filter(
  //         (item) => item._id == element?.clientId
  //       );
  //       element["id"] = element?._id;
  //       element["date"] = moment(element.date).format("DD-MM-YYYY");
  //       element["clientName"] = filtered && filtered[0]?.clientName;
  //     }
  //   });
  // }
  const columns = [
    { field: "date", headerName: "Date" },
    { field: "show_voucher_no", headerName: "Invoice#", width: 150 },
    { field: "client_name", headerName: "Client", width: 300 },
    // { field: "clientName", headerName: "Client", width: 250 },
    // {
    //   field: "seller",
    //   headerName: "Seller",
    //   width: 150,
    // },
    {
      field: "amount",
      headerName: "Total Amount",
      sortable: true,
      width: 150
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <>
          <Btn
            sx={{ height: '30px', mr: '5px', padding: 0 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => view(params.row)}>
            View Bill
          </Btn>
          <Btn
            title="Edit"
            variant="contained"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#eed202", color: "white" }}
            onClick={() => addSales(params.row)}>
            {/* <AppReg /> */}
            <EditIcon fontSize="small" />
          </Btn>
          <Btn
            title="Delete"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "red", color: "white" }}
            variant="contained"
            onClick={() => deleteRow(params.row)}>
            <DeleteIcon fontSize="small" />
          </Btn>
        </>
      ),
    },
  ];
  const rows = rowData !== undefined ? rowData : [];
  const deleteRow = async (params) => {
    /* eslint-disable no-restricted-globals */
    if (confirm(`Are you sure to delete Invoice#"${params.voucher_no}"`)) {
      let res = await deleteBillZkTraders(params.voucher_no);
      if (res.success) {
        toast.error("Sale deleted successfully");
        getBillData();
      }
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
